<template>
  <v-text-field
    v-bind="$input"
     v-on="$listeners"
    :label="label"
    class="derecha"
    :simbolo="simbolo"
    :disabled="disabled"
    :readonly="readonly"
    v-model="displayValue"
    @blur="focus_lost"
    @keyup="event_capture('change_input_n')"
    @focus="confoco"
  >
  </v-text-field>
</template>
<script>
// value: { type: [Number, String], default: "" },
export default {
  props: {
    value: { type: [String, Number], default: "0" },
    label: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    simbolo:{ type: String, default: "€" }
  },

  data: function() {
    return {
      isInputActive: false,
      
    };
  },

  computed: {
    displayValue: {
      get: function() {
        if (this.isInputActive) {
          // Cursor is inside the input field. unformat display value for user
          return this.value.toString();
        } else {
          // User is not modifying now. Format display value for user interface
          let t = Number(this.value)
            .toFixed(2)
            .toString();
          // this.value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")+" cm"
          return t.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") + " "+this.simbolo;
        }
      },
      set: function(modifiedValue) {
        // Recalculate value after ignoring "$" and "," in user input
        let newValue = parseFloat(modifiedValue.replace(/[^\d.]/g, ""));
        // Ensure that it is not NaN
        if (isNaN(newValue)) {          
          newValue = 0;
        }
        // Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component
        // $emit the event so that parent component gets it
        this.$emit("input", newValue);
      }
    }
  },
  methods: {
    confoco() {
      this.isInputActive = true;
    },

    event_capture(accion) {
      if (!accion) return;

      this.$emit("onEvent", { accion: accion });
    },
    focus_lost(e){
      this.isInputActive = false;
      this.$emit('blur', e);
    }
  }
};
</script>

<style>
.derecha input {
  text-align: right;
}
</style>
